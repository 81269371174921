/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        /**
        * Main Menu
        **/

        $(document).ready(function () {
          var trigger = $('.hamburger'),
              overlay = $('.overlay'),
             isClosed = false;

            function hamburger_cross() {

              if (isClosed === true) {
                overlay.hide();
                trigger.removeClass('is-open');
                trigger.addClass('is-closed');
                isClosed = false;
              } else {
                overlay.show();
                trigger.removeClass('is-closed');
                trigger.addClass('is-open');
                isClosed = true;
              }
          }

          trigger.click(function () {
            hamburger_cross();
          });

          $('[data-toggle="offcanvas"]').click(function () {
                $('#wrapper').toggleClass('toggled');
          });

        	//Smartmenu

          // SmartMenus init
          $(function() {
            $('#main-nav').smartmenus( {
              noMouseOver: true,
              hideOnClick: true
            } );
          });

          // SmartMenus mobile menu toggle button
          $(function() {
            var $mainMenuState = $('#main-menu-state');
            if ($mainMenuState.length) {
              // animate mobile menu
              $mainMenuState.change(function(e) {
                var $menu = $('#main-menu');
                if (this.checked) {
                  $menu.hide().slideDown(250, function() { $menu.css('display', ''); });
                } else {
                  $menu.show().slideUp(250, function() { $menu.css('display', ''); });
                }
              });
              // hide mobile menu beforeunload
              $(window).bind('beforeunload unload', function() {
                if ($mainMenuState[0].checked) {
                  $mainMenuState[0].click();
                }
              });
            }
          });

        });


        /**
        * End of Main Menu
        **/
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        function checkIntroCookie(){
          if (!Cookies.get('skip')) {
            //alert("no cookie");
            window.location.assign('http://shona.ie/introduction/');
            Cookies.set('skip', 'value', { expires: 6 });
          }
        }

        // checkIntroCookie();

        $(document).ready(function() {
          $('#myTab .category-item:nth-child(5) a').tab('show');
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'introduction': {
      init: function() {
        // JavaScript to be fired on the about us page

        $(document).ready(function(){

          //Slide 2
          $('#slide-2-header').viewportChecker({
            classToAdd: 'animated fadeInDown visible', // Class to add to the elements when they are visible,
            classToRemove: 'invisible', // Class to remove before adding 'classToAdd' to the elements
            offset: 300, // The offset of the elements (let them appear earlier or later). This can also be percentage based by adding a '%' at the end
          });
          $('#slide-2-text').viewportChecker({
            classToAdd: 'animated fadeInRight visible', // Class to add to the elements when they are visible,
            classToRemove: 'invisible', // Class to remove before adding 'classToAdd' to the elements
            offset: 300, // The offset of the elements (let them appear earlier or later). This can also be percentage based by adding a '%' at the end
          });
          $('#slide-2-image img').viewportChecker({
            classToAdd: 'visible animated slideInUp', // Class to add to the elements when they are visible,
            classToRemove: 'invisible', // Class to remove before adding 'classToAdd' to the elements
            offset: 300, // The offset of the elements (let them appear earlier or later). This can also be percentage based by adding a '%' at the end
          });


          //Slide 3
          $('#slide-3-header').viewportChecker({
            classToAdd: 'animated bounceInLeft visible', // Class to add to the elements when they are visible,
            classToRemove: 'invisible', // Class to remove before adding 'classToAdd' to the elements
            offset: 200, // The offset of the elements (let them appear earlier or later). This can also be percentage based by adding a '%' at the end
          });

          $('#slide-3-text').viewportChecker({
            classToAdd: 'animated fadeInLeft visible', // Class to add to the elements when they are visible,
            classToRemove: 'invisible', // Class to remove before adding 'classToAdd' to the elements
            offset: 200, // The offset of the elements (let them appear earlier or later). This can also be percentage based by adding a '%' at the end
          });
          $('#slide-3-image img').viewportChecker({
            classToAdd: 'visible animated slideInUp', // Class to add to the elements when they are visible,
            classToRemove: 'invisible', // Class to remove before adding 'classToAdd' to the elements
            offset: 300, // The offset of the elements (let them appear earlier or later). This can also be percentage based by adding a '%' at the end
          });


          //Slide 4
          $('#slide-4-header').viewportChecker({
            classToAdd: 'animated slideInRight visible', // Class to add to the elements when they are visible,
            classToRemove: 'invisible', // Class to remove before adding 'classToAdd' to the elements
            offset: 200, // The offset of the elements (let them appear earlier or later). This can also be percentage based by adding a '%' at the end
          });

          $('#slide-4-text').viewportChecker({
            classToAdd: 'animated slideInUp visible', // Class to add to the elements when they are visible,
            classToRemove: 'invisible', // Class to remove before adding 'classToAdd' to the elements
            offset: 200, // The offset of the elements (let them appear earlier or later). This can also be percentage based by adding a '%' at the end
          });

          $('#slide-4-image img').viewportChecker({
            classToAdd: 'visible animated slideInUp', // Class to add to the elements when they are visible,
            classToRemove: 'invisible', // Class to remove before adding 'classToAdd' to the elements
            offset: 300, // The offset of the elements (let them appear earlier or later). This can also be percentage based by adding a '%' at the end
          });


          //Slide 5
          $('#slide-5-header').viewportChecker({
            classToAdd: 'animated bounceInLeft visible', // Class to add to the elements when they are visible,
            classToRemove: 'invisible', // Class to remove before adding 'classToAdd' to the elements
            offset: 200, // The offset of the elements (let them appear earlier or later). This can also be percentage based by adding a '%' at the end
          });

          $('#slide-5-text').viewportChecker({
            classToAdd: 'animated slideInLeft visible', // Class to add to the elements when they are visible,
            classToRemove: 'invisible', // Class to remove before adding 'classToAdd' to the elements
            offset: 200, // The offset of the elements (let them appear earlier or later). This can also be percentage based by adding a '%' at the end
          });

          $('#slide-5-image img').viewportChecker({
            classToAdd: 'visible animated slideInUp', // Class to add to the elements when they are visible,
            classToRemove: 'invisible', // Class to remove before adding 'classToAdd' to the elements
            offset: 300, // The offset of the elements (let them appear earlier or later). This can also be percentage based by adding a '%' at the end
          });


        });


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
